'use client';

import { Button } from '@axiom-ui/react/button';
import { Body, Heading } from '@axiom-ui/react/typography';

import { Logo } from '@/modules/shared/ui/team-components/logo';
import { PageFeedback } from '@/modules/shared/ui/team-components/page-feedback';

export default function NotFound() {
  const previousPageClick = () => {
    window.history.back();
  };
  return (
    <PageFeedback>
      <PageFeedback.Info>
        <PageFeedback.Body>
          <Heading variant="bold" size="xxl" className="text-pure">
            404
          </Heading>
          <Heading variant="bold" size="md" className="text-pure">
            A página que você está procurando não existe.
          </Heading>
          <Body size="xs">
            Você pode ter digitado algo errado ou talvez este conteúdo tenha
            sido movido para outro endereço.
            <br />
            Se estiver com dúvidas, acesse nossa central de suporte pelo botão
            abaixo.
          </Body>
        </PageFeedback.Body>

        <PageFeedback.Actions>
          <Button variant="ghost" onClick={previousPageClick}>
            Voltar à página anterior
          </Button>
          <Button asChild>
            <a href="https://suporte.iclinic.com.br/perguntas-frequentes">
              Acessar a central
            </a>
          </Button>
        </PageFeedback.Actions>
      </PageFeedback.Info>
      <Logo />
    </PageFeedback>
  );
}
